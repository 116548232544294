import {
    Button,
    Card,
    Checkbox,
    Container,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TextField,
    Typography,
} from '@mui/material';
import {
    applyFilter,
    emptyRows,
    getComparator,
} from '../../golfInformation/view/utils';
import { useEffect, useRef, useState } from 'react';
import BookingTableToolbar from './booking-table-toolbar';
import TableEmptyRows from '../../golfInformation/view/table-empty-rows';
import TableNoData from '../../golfInformation/view/table-no-data';
import Scrollbar from '../../../component/scrollbar/scrollbar';
import BookingTableHead from './booking-table-head';
import BookingTableRow from './booking-table-row';
import { Link } from 'react-router-dom';
import StatusBox from '../../../component/statusBox';
import ModalConfirm from '../../../component/modalConfirm';
import moment from 'moment';
import { parseNumber } from '../../../utils/common';
import useFetchData from '../../../hooks/use-fetchData';
import usePostData from '../../../hooks/use-postData';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../recoil/authState';
import API from '../../../utils/api';

export default function BookingDiscountView() {
    const auth = useRecoilValue(authState);

    const today = new Date();

    const me = auth.user;

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [filterField, setFilterField] = useState('club_name');

    const [discountLists, setDiscountLists] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalOnSuccess, setModalOnSuccess] = useState(null);

    const [editIndex, setEditIndex] = useState(null);
    const [formData, setFormData] = useState({});
    const [focusedField, setFocusedField] = useState(null);

    const [searchData, setSearchData] = useState({
        range: 'all',
        rangeStart: moment(today).format('YYYY-MM-DD'),
        rangeEnd: moment(today).format('YYYY-MM-DD'),
        status: 'all',
    });

    const { fetchData } = useFetchData();
    const { postData } = usePostData();

    const getDataRef = useRef(fetchData);
    const inputRefs = useRef({});

    const CustomTextField = ({
        name,
        value,
        handleChange,
        sx = { maxWidth: 100 },
    }) => (
        <TextField
            inputRef={(el) => (inputRefs.current[name] = el)}
            name={name}
            value={value}
            size="small"
            sx={{ ...sx }}
            onChange={handleChange}
        />
    );

    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = discountLists.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleSearchButton = (data) => {
        setSearchData(data);
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
        setFormData(discountLists.find((lists) => lists.id === index));
    };

    const handleSaveClick = () => {
        setModalTitle('저장');
        setModalOnSuccess(() => () => handleSave());
        setModalOpen(true);
    };

    const handleSave = () => {
        const parsedData = {
            ...formData,
            golf_time: moment(formData.golf_time).format('YYYY-MM-DD HH:mm:ss'),
            green_fee: parseNumber(formData.green_fee),
            green_fee_discount: parseNumber(formData.green_fee_discount),
            cart_fee: parseNumber(formData.cart_fee),
            caddie_fee: parseNumber(formData.caddie_fee),
        };
        const updatedDiscountLists = discountLists.map((lists) =>
            lists.id === editIndex ? parsedData : lists
        );

        const params = {
            data: parsedData,
        };

        postData(`${API.DISCOUNT_UPDATE}/${parsedData.id}`, params, (res) => {
            setDiscountLists(updatedDiscountLists);
            setEditIndex(null); // 수정 모드 종료
            setModalOpen(false);
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: parseNumber(value),
        }));
        setFocusedField(name);
    };

    const statusButton = (status, item) => {
        if (status === 1) {
            return (
                <div>
                    {editIndex === item.id ? (
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ mr: 1 }}
                            onClick={handleSaveClick}
                        >
                            저장
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mr: 1 }}
                            onClick={() => handleEditClick(item.id)}
                        >
                            수정
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => statusButtonClick([item.id], 'deleted')}
                    >
                        등록 삭제
                    </Button>
                </div>
            );
        }
    };

    const statusButtonClick = (item, state) => {
        setModalTitle('등록 삭제');
        setModalOnSuccess(() => () => postStateTransition(item, state));
        setModalOpen(true);
    };

    const postStateTransition = (item, state) => {
        const params = {
            state: state,
            data: item,
        };

        postData(API.DISCOUNT_STATE_TRANSITION, params, () => {
            setDiscountLists(
                discountLists.filter((list) => !item.includes(list.id))
            );
            setSelected([]);
            setModalOpen(false);
        });
    };

    const dataFiltered = applyFilter({
        inputData: discountLists,
        comparator: getComparator(order, orderBy),
        filterName,
        field: filterField,
    });

    const notFound = !dataFiltered.length && !!filterName;

    useEffect(() => {
        getDataRef.current = fetchData;
    }, [fetchData]);

    useEffect(() => {
        getDataRef.current(API.DISCOUNT_LISTS, { ...searchData }, (data) => {
            setDiscountLists(data);
        });
    }, [searchData]);

    useEffect(() => {
        if (focusedField && inputRefs.current[focusedField]) {
            inputRefs.current[focusedField].focus();
        }
    }, [focusedField, formData]);

    return (
        <Container maxWidth="true">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={5}
                mb={5}
            >
                <Typography variant="h4">할인부킹 관리</Typography>

                {me.club_admin_level !== 10 && (
                    <Link to={`/bookingdiscount/create/${me.club_id}`}>
                        <Button variant="contained" color="inherit">
                            부킹 등록
                        </Button>
                    </Link>
                )}
            </Stack>
            <Card>
                <BookingTableToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    onSearchButton={handleSearchButton}
                    onDeleteButton={() =>
                        statusButtonClick(selected, 'deleted')
                    }
                />

                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <BookingTableHead
                                order={order}
                                orderBy={orderBy}
                                rowCount={discountLists.length}
                                numSelected={selected.length}
                                onRequestSort={handleSort}
                                onSelectAllClick={handleSelectAllClick}
                                headLabel={[
                                    // {
                                    //     id: 'checkbox',
                                    //     label: (
                                    //         <Checkbox
                                    //             indeterminate={
                                    //                 selected.length > 0 &&
                                    //                 selected.length <
                                    //                     discountLists.length
                                    //             }
                                    //             checked={
                                    //                 discountLists.length > 0 &&
                                    //                 selected.length ===
                                    //                     discountLists.length
                                    //             }
                                    //             onChange={handleSelectAllClick}
                                    //         />
                                    //     ),
                                    // },
                                    { id: 'checkbox', label: '체크' },
                                    { id: 'id', label: 'No' },
                                    me.club_admin_level === 10
                                        ? { id: 'club_name', label: '골프장' }
                                        : undefined,
                                    { id: 'courses', label: '코스' },
                                    { id: 'holes', label: '홀수' },
                                    { id: 'golf_time', label: '부킹일시' },
                                    { id: 'green_fee', label: '그린피' },
                                    {
                                        id: 'green_fee_discount',
                                        label: '할인금액',
                                    },
                                    { id: 'cart_fee', label: '카트피' },
                                    { id: 'caddie_fee', label: '캐디피' },
                                    { id: 'golf_people', label: '인원' },
                                    {
                                        id: 'cancel_deadline',
                                        label: '취소시한',
                                    },
                                    { id: 'status', label: '상태' },
                                    { id: 'process', label: '처리' },
                                    // { id: '' },
                                ]}
                            />
                            <TableBody>
                                {dataFiltered
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const expired =
                                            today > new Date(row.golf_time);
                                        if (row.status === 1 && expired)
                                            row.status = 6;

                                        let editValue = [];
                                        if (editIndex === row.id) {
                                            editValue = [
                                                <TextField
                                                    type="datetime-local"
                                                    name="golf_time"
                                                    value={formData.golf_time}
                                                    size="small"
                                                    sx={{ maxWidth: 220 }}
                                                    inputProps={{
                                                        sx: {
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                />,
                                                <CustomTextField
                                                    name="green_fee"
                                                    value={new Intl.NumberFormat().format(
                                                        formData.green_fee
                                                    )}
                                                    handleChange={
                                                        handleInputChange
                                                    }
                                                />,
                                                <CustomTextField
                                                    name="green_fee_discount"
                                                    value={new Intl.NumberFormat().format(
                                                        formData.green_fee_discount
                                                    )}
                                                    handleChange={
                                                        handleInputChange
                                                    }
                                                />,
                                                <CustomTextField
                                                    name="cart_fee"
                                                    value={new Intl.NumberFormat().format(
                                                        formData.cart_fee
                                                    )}
                                                    handleChange={
                                                        handleInputChange
                                                    }
                                                />,
                                                <CustomTextField
                                                    name="caddie_fee"
                                                    value={new Intl.NumberFormat().format(
                                                        formData.caddie_fee
                                                    )}
                                                    handleChange={
                                                        handleInputChange
                                                    }
                                                />,
                                            ];
                                        } else {
                                            editValue = [
                                                row.golf_time,
                                                new Intl.NumberFormat().format(
                                                    row.green_fee
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.green_fee_discount
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.cart_fee
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.caddie_fee
                                                ),
                                            ];
                                        }

                                        return (
                                            <BookingTableRow
                                                key={row.id}
                                                selected={
                                                    selected.indexOf(row.id) !==
                                                    -1
                                                }
                                                index={index}
                                                editIndex={editIndex}
                                                handleClick={(event) =>
                                                    handleClick(event, row.id)
                                                }
                                                rowValue={[
                                                    row.status === 1 ? (
                                                        <Checkbox
                                                            disableRipple
                                                            checked={
                                                                selected.indexOf(
                                                                    row.id
                                                                ) !== -1
                                                            }
                                                            onChange={(event) =>
                                                                handleClick(
                                                                    event,
                                                                    row.id
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    ),
                                                    row.id,
                                                    me.club_admin_level === 10
                                                        ? row.club_name
                                                        : undefined,
                                                    row.courses,
                                                    row.holes,
                                                    ...editValue,
                                                    row.golf_people +
                                                        (row.golf_people === 4
                                                            ? '인필수'
                                                            : '인이상'),
                                                    row.cancel_not_possible
                                                        ? '취소불가'
                                                        : row.cancel_deadline,
                                                    <StatusBox
                                                        status={row.status}
                                                        type="text"
                                                    />,
                                                    statusButton(
                                                        row.status,
                                                        row
                                                    ),
                                                ]}
                                            />
                                        );
                                    })}

                                <TableEmptyRows
                                    height={77}
                                    emptyRows={emptyRows(
                                        page,
                                        rowsPerPage,
                                        discountLists.length
                                    )}
                                />

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    page={page}
                    component="div"
                    count={discountLists.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[50, 100, 200]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
            <ModalConfirm
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title={modalTitle}
                content={modalTitle + ' 하시겠습니까?'}
                closeText={'닫기'}
                successText={'확인'}
                onCloseClick={() => setModalOpen(false)}
                onSuccessClick={modalOnSuccess}
            />
        </Container>
    );
}
