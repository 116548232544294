import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { Suspense } from 'react';
import Layout from '../layouts';
import useLog from '../hooks/useLog';

const PrivateRoute = ({ children }) => {
    const auth = useRecoilValue(authState);
    useLog();
    
    return auth.isLoggedIn ? (
        <Layout>
            <Suspense>
                <Outlet />
            </Suspense>
        </Layout>
    ) : (
        <Navigate to={`/login`} />
    );
};

export default PrivateRoute;
