const BASE_URL = '/club/admin';

const API = {
    DISCOUNT_LISTS: `${BASE_URL}/discountLists`,
    DISCOUNT_RSVPS_LISTS: `${BASE_URL}/discountRsvpsLists`,
    DISCOUNT_UPDATE: `${BASE_URL}/discountUpdate`,
    DISCOUNT_STATE_TRANSITION: `${BASE_URL}/stateTransition`,
    DISCOUNT_CLUB_INFO: `${BASE_URL}/info`,
    DISCOUNT_INSERT: `${BASE_URL}/discountInsert`,
};

export default API;
