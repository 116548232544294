import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { Suspense } from 'react';

const MasterRoute = () => {
    const auth = useRecoilValue(authState);
    const isTeeshotMaterAuth = auth?.user?.club_admin_level === 10;

    return isTeeshotMaterAuth ? (
        // <Layout>
        <Suspense>
            <Outlet />
        </Suspense>
    ) : (
        // </Layout>
        <Navigate to={`/`} />
    );
};

export default MasterRoute;
