import { useState } from 'react';
import {
    AppBar,
    Box,
    IconButton,
    MenuItem,
    Popover,
    Typography,
    Stack,
    Toolbar,
} from '@mui/material';
import { DensityMedium } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from '../hooks/use-responsive';

import { bgBlur } from '../theme/css';

import { NAV, HEADER } from './config-layout';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';

export default function Header() {
    const setAuth = useSetRecoilState(authState);
    const auth = useRecoilValue(authState);

    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const theme = useTheme();
    const lgUp = useResponsive('up', 'lg');

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(null);
    };
    const logoutHandle = () => {
        setAuth({
            isLoggedIn: false,
            user: null,
            token: null,
        });
        localStorage.clear();
        navigate('login');
    };
    return (
        <AppBar
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: HEADER.H_DESKTOP,
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                <Stack
                    display={'flex'}
                    width={'100%'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Stack flexGrow={1}>{/* {페이지 제목} */}</Stack>
                    <Stack display={'flex'} flexDirection={'row'} gap={2}>
                        <Typography sx={{ color: 'black' }}>
                            {auth.user.club_admin_id}
                        </Typography>
                        <Stack>
                            <IconButton
                                onClick={handleOpen}
                                aria-label="delete"
                                size="small"
                            >
                                <DensityMedium fontSize="small" />
                            </IconButton>
                            <Popover
                                open={!!open}
                                anchorEl={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    disableRipple
                                    disableTouchRipple
                                    onClick={logoutHandle}
                                    sx={{
                                        typography: 'body2',
                                        color: 'error.main',
                                        py: 1.5,
                                    }}
                                >
                                    로그아웃
                                </MenuItem>
                            </Popover>
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
