import { useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { Suspense, useEffect } from 'react';
import useTeeshotApiRequest from './use-teeshotApiRequest';

const useLog = () => {
    const auth = useRecoilValue(authState);
    const { request } = useTeeshotApiRequest();
    useEffect(() => {
        request({ method: 'POST', url: `/club/admin/yearBooking/log` });
    }, [auth]);
};

export default useLog;
