import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../component/iconify';
import { useStatus } from '../../../contexts/statusContext';
import { useState } from 'react';
import moment from 'moment';

export default function BookingTableToolbar({
    numSelected,
    filterName,
    onFilterName,
    onSearchButton,
    onDeleteButton,
}) {
    const statusData = useStatus();

    const today = new Date();

    const [searchRange, setSearchRange] = useState('all');
    const [searchRangeStart, setSearchRangeStart] = useState(
        moment(today).format('YYYY-MM-DD')
    );
    const [searchRangeEnd, setSearchRangeEnd] = useState(
        moment(today).format('YYYY-MM-DD')
    );
    const [searchStatus, setSearchStatus] = useState('all');

    const handleSearchRange = (event) => {
        setSearchRange(event.target.value);
    };

    const handleSearchRangeStart = (event) => {
        setSearchRangeStart(event.target.value);
    };

    const handleSearchRangeEnd = (event) => {
        setSearchRangeEnd(event.target.value);
    };

    const handleSearchStatus = (event) => {
        setSearchStatus(event.target.value);
    };

    const handleSearchButton = () => {
        onSearchButton({
            range: searchRange,
            rangeStart: searchRangeStart,
            rangeEnd: searchRangeEnd,
            status: searchStatus,
        });
    };

    return (
        <Toolbar
            sx={{
                height: 96,
                display: 'flex',
                // justifyContent: 'space-between',
                p: (theme) => theme.spacing(0, 1, 0, 3),
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >
            {numSelected > 0 ? (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => onDeleteButton()}
                >
                    {numSelected} 선택 삭제
                </Button>
            ) : (
                <>
                    {/* <OutlinedInput
                        value={filterName}
                        onChange={onFilterName}
                        placeholder="Search user..."
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify
                                    icon="eva:search-fill"
                                    sx={{
                                        color: 'text.disabled',
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </InputAdornment>
                        }
                    /> */}
                    <Select
                        labelId="searchRange-label"
                        id="searchRange"
                        value={searchRange}
                        // size="small"
                        displayEmpty
                        onChange={handleSearchRange}
                    >
                        <MenuItem value="all">전체기간</MenuItem>
                        <MenuItem value="date">기간지정</MenuItem>
                    </Select>
                    <TextField
                        type="date"
                        id="searchRangeStart"
                        value={searchRangeStart}
                        // size="small"
                        sx={{ ml: 1, minWidth: 120 }}
                        onChange={handleSearchRangeStart}
                        disabled={searchRange === 'all' ? true : false}
                    />
                    <Box component="span" sx={{ ml: 1 }}>
                        ~
                    </Box>
                    <TextField
                        type="date"
                        id="searchRangeEnd"
                        value={searchRangeEnd}
                        // size="small"
                        sx={{ ml: 1, minWidth: 120 }}
                        onChange={handleSearchRangeEnd}
                        disabled={searchRange === 'all' ? true : false}
                    />
                    <Select
                        labelId="status-label"
                        id="status"
                        value={searchStatus}
                        // size="small"
                        displayEmpty
                        sx={{ ml: 1, minWidth: 100 }}
                        onChange={handleSearchStatus}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {statusData.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.text}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ ml: 1 }}
                        onClick={handleSearchButton}
                    >
                        조회
                    </Button>
                </>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list" sx={{ display: 'none' }}>
                    <IconButton>
                        <Iconify icon="ic:round-filter-list" />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

BookingTableToolbar.propTypes = {
    numSelected: PropTypes.number,
    onSearchButton: PropTypes.func,
    onDeleteButton: PropTypes.func,
};
