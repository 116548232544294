import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import MasterRoute from './masterRoute';

export const Home = lazy(() => import('../pages/dashBoard/Home'));
export const Login = lazy(() => import('../pages/Login'));
export const Repassword = lazy(() => import('../pages/Repassword'));
export const BookingDiscount = lazy(() => import('../pages/bookingDiscount'));
export const BookingDiscountCreate = lazy(() =>
    import('../pages/bookingDiscount/create')
);
export const BookingDiscountReservation = lazy(() =>
    import('../pages/bookingDiscount/reservation')
);
export const GolfInfomation = lazy(() => import('../pages/golfInformation'));
export const GolfInfomationModify = lazy(() =>
    import('../pages/golfInformation/modify')
);
export const GolfAcount = lazy(() => import('../pages/golfAccount'));
export const YearBookingList = lazy(() => import('../pages/yearBookingList'));
export const YearBookingDetail = lazy(() =>
    import('../pages/yearBookingDetail')
);
export const YearBookingRoundingList = lazy(() =>
    import('../pages/yearBookingRoundingList')
);
export const YearBookingRoundingDetail = lazy(() =>
    import('../pages/yearBookingRoundingDetail')
);
export const YearBookingGroups = lazy(() =>
    import('../pages/yearBookingGroups')
);
export const YearBookingGroupsDetail = lazy(() =>
    import('../pages/yearBookingGroups/id')
);
export const NoYearBookingGroups = lazy(() =>
    import('../pages/noYearBookingGroups')
);
export const LogList = lazy(() => import('../pages/logList'));

export default function Router() {
    const routes = useRoutes([
        // {
        //     path: '/',
        //     element: <Home />,
        //     index: true,
        // },
        {
            path: '/',
            element: <PrivateRoute />,
            children: [
                {
                    element: <MasterRoute />,
                    children: [
                        {
                            path: 'noYearBookingGroups',
                            element: <NoYearBookingGroups />,
                        },
                    ],
                },
                { element: <Home />, index: true },
                { path: 'bookingdiscount', element: <BookingDiscount /> },
                {
                    path: 'bookingdiscount/create/:id',
                    element: <BookingDiscountCreate />,
                },
                {
                    path: 'bookingdiscountrsvps',
                    element: <BookingDiscountReservation />,
                },
                { path: 'golfinfo', element: <GolfInfomation /> },
                {
                    path: 'golfinfo/modify/:id',
                    element: <GolfInfomationModify />,
                },
                { path: 'golfacount', element: <GolfAcount /> },
                { path: 'logList', element: <LogList /> },
                { path: 'yearBookingList', element: <YearBookingList /> },
                { path: 'yearBookingList/:id', element: <YearBookingDetail /> },
                {
                    path: 'yearBookingRoundingList',
                    element: <YearBookingRoundingList />,
                },
                {
                    path: 'yearBookingRoundingList/:id',
                    element: <YearBookingRoundingDetail />,
                },
                {
                    path: 'yearBookingGroups',
                    element: <YearBookingGroups />,
                },
                {
                    path: 'yearBookingGroups/:id',
                    element: <YearBookingGroupsDetail />,
                },
            ],
        },
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'repassword',
            element: <Repassword />,
        },
    ]);

    return routes;
}
