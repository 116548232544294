export function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseNumber(formattedNumber) {
    return formattedNumber.toString().replace(/,/g, '');
}

export function commaNumber(value) {
    return formatNumber(parseNumber(value.replace(/[^\d,]/g, '')));
}

export function digit4ToTimeString(digit4String) {
    if (!digit4String || digit4String?.length !== 4) {
        return null;
    }
    return [digit4String.slice(0, 2), digit4String.slice(2, 4)].join(':');
}
export function timeStringTo4Digit(timeString) {
    if (
        !timeString?.length ||
        timeString.length !== 5 ||
        timeString?.[2] !== ':'
    ) {
        return null;
    }
    const returnString = timeString;
    return `${returnString.slice(0, 2)}${returnString.slice(3, 5)}`;
}

export function getNthWeekDayOfMonth(year, month, weekNumber, dayOfWeek) {
    month = month - 1;
    dayOfWeek = dayOfWeek + 1;
    // Create a date object for the first day of the given month (month is 0-based)
    let firstDayOfMonth = new Date(year, month, 1);

    // Get the day of the week of the first day of the month (0 for Sunday, 1 for Monday, etc.)
    let firstDayOfWeek = firstDayOfMonth.getDay();

    // Calculate the offset to the first occurrence of the desired day of the week
    let dayOffset = (dayOfWeek - firstDayOfWeek + 7) % 7;

    // Calculate the day of the nth week
    let nthWeekDay = 1 + dayOffset + (weekNumber - 1) * 7;

    // Ensure the calculated day is within the month
    let date = new Date(year, month, nthWeekDay);
    if (date.getMonth() !== month) {
        return null; // If the day goes beyond the month's last day
    }

    return date;
}

export function urlSearchParamsToObject(urlSearchParams) {
    const obj = {};
    urlSearchParams.forEach((value, key) => {
        obj[key] = value;
    });
    return obj;
}

export function dateFormat(date, withTime = false) {
    try {
        const nd = new Date(date);
        const yy = String(nd.getFullYear()).slice(2, 4);
        const mm = String(nd.getMonth() + 1).padStart(2, '0');
        const dd = String(nd.getDate()).padStart(2, '0');
        const newDateString = `${yy}.${mm}.${dd}`;
        const timeString = nd.toLocaleTimeString('kr-KR', {
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23',
        });
        const dayStr = {
            0: '(일)',
            1: '(월)',
            2: '(화)',
            3: '(수)',
            4: '(목)',
            5: '(금)',
            6: '(토)',
        }[nd.getDay()];
        return `${newDateString} ${dayStr}${withTime ? ` ${timeString}` : ''}`;
    } catch {
        console.log('error on dateFormat function');
        return null;
    }
}

export function parseJsonStr(jsonString) {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return null;
    }
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export async function copyToClipboard(stringToCopy) {
    try {
        fallbackCopyTextToClipboard(stringToCopy);
    } catch {
        try {
            await navigator.clipboard.writeText(stringToCopy);
        } catch (e) {
            alert('복사실패');
        }
    }
}

export async function sleep(milSec=500) {
    return new Promise((res) => {
        setTimeout(() => {
            res(1)
        }, milSec)
    })
}
