import { useState, useCallback } from 'react';
import axios from 'axios';

export default function useTeeshotApiRequest(
    baseURL = process.env.REACT_APP_API_URL
) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
        },
    });

    const request = useCallback(
        async ({
            method = 'get',
            url,
            data = {},
            params = {},
            onSuccess = (a) => {},
            onError = (a) => {},
        }) => {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance({
                    method,
                    url,
                    data,
                    params,
                });

                if (response.data.success === false) {
                    throw new Error(response.data.message);
                }
                setData(response.data?.data);
                if (onSuccess) onSuccess(response.data?.data);
                return response.data?.data
            } catch (err) {
                setError(err);
                if (onError) onError(err);
            } finally {
                setLoading(false);
            }
        },
        [axiosInstance]
    );

    return { loading, error, data, request };
}
